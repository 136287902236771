const showBtn = document.querySelector(".JS-menuShow");
const hideBtn = document.querySelector(".JS-menuHide");
const menuBody = document.querySelector(".JS-siteNav");

const menuShow = () => {
  showBtn.addEventListener("click", function () {
    menuBody.classList.add("sp-show");
  });
};
const menuHide = () => {
  hideBtn.addEventListener("click", function () {
    menuBody.classList.remove("sp-show");
  });
};

menuShow();
menuHide();
